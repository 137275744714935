import React, { useState } from 'react';
import { Switch } from 'components/foundation';
import OrganizationResources, { UpdateOrganizationEntity } from 'remote/resources/organizations';
import { Organization } from 'types/organization';
import { useNotify } from 'state/notifications';

export interface OrgLoginMethodsProps {
  organization: Organization;
  refetchOrganization: () => void;
  disabled?: boolean;
}

export const OrgLoginMethods = ({
  organization,
  refetchOrganization,
  disabled,
}: OrgLoginMethodsProps) => {
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const updateOrganization = async (entity: UpdateOrganizationEntity) => {
    setLoading(true);
    try {
      await OrganizationResources.update(organization.id, entity);
      refetchOrganization();
    } catch (e) {
      notify.error(e.responseMessage || 'Failed to update organization');
    }
    setLoading(false);
  };
  const handleGoogleLoginEnabledChange = e => {
    const value = e.target.checked;
    updateOrganization({ googleLoginEnabled: value });
  };
  const handlePasswordLoginEnabledChange = e => {
    const value = e.target.checked;
    updateOrganization({ passwordLoginEnabled: value });
  };
  const handleMicrosoftLoginEnabledChange = e => {
    const value = e.target.checked;
    updateOrganization({ microsoftLoginEnabled: value });
  };
  return (
    <div className="tw-flex tw-flex-col tw-gap-2 tw-mt-4">
      <Switch
        checked={organization.passwordLoginEnabled}
        label="Allow email/password login"
        disabled={loading || disabled}
        onChange={handlePasswordLoginEnabledChange}
        data-testid="email-password-toggle"
      />
      <Switch
        checked={organization.googleLoginEnabled}
        label="Allow Google social login"
        disabled={loading || disabled}
        onChange={handleGoogleLoginEnabledChange}
        data-testid="google-toggle"
      />
      <Switch
        checked={organization.microsoftLoginEnabled}
        label="Allow Microsoft social login"
        disabled={loading || disabled}
        onChange={handleMicrosoftLoginEnabledChange}
        data-testid="microsoft-toggle"
      />
    </div>
  );
};
