import React, { useState } from 'react';
import { useLocation, useSession } from 'store';
import { Alert, Button } from 'foundation';
import authenticationClient from 'actions/authentication';
import { isString } from 'utils/strings';
import Actions from 'actions';
import UsersResource from 'remote/resources/users';
import { useNotify } from 'state/notifications';
import logger from 'logger';
import Neo4jLogo from 'ui/icons/aura-colored.svg';
import LoadError from 'components/application/load-error';
import { parse } from 'query-string';
import location from 'browser/location';

const LinkAccountsPage = () => {
  const { oauthBearerToken, accountToLink } = useSession();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{ error: string; error_description: string } | null>(null);
  const notify = useNotify();
  const loc = useLocation();
  const params = parse(loc.search);

  const email = accountToLink ? accountToLink.email : params?.email;
  const connection = accountToLink ? accountToLink.connection : params?.connection;

  if (!email || !connection) {
    return (
      <div className="tw-flex tw-justify-center tw-mt-12">
        <LoadError description="Missing account link details." />
      </div>
    );
  }

  const handleLink = async () => {
    setLoading(true);
    let token;
    try {
      token = await authenticationClient.getTokenForConnectionAndEmail(
        connection as string,
        email as string
      );
    } catch (e) {
      logger.error(e);
      setError(e);
      setLoading(false);
      return;
    }

    try {
      await UsersResource.linkLogins(oauthBearerToken, token);
      const url = new URL(location.href);
      url.pathname = '/';
      location.replace(url); // Navigate to home after linking with a full refresh of the page
    } catch (e) {
      logger.error(e);
      notify.error('Failed to link accounts');
    }

    setLoading(false);
  };

  const handleLogout = () => {
    Actions.navigate.push({ hash: 'logout' });
  };

  if (!isString(connection) || !isString(email)) {
    return (
      <div className="tw-flex tw-justify-center tw-mt-12">
        <LoadError
          title="Something went wrong"
          description="Email and/or connection are invalid for linking."
        />
      </div>
    );
  }

  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-p-2">
        <Neo4jLogo />
      </div>
      <div className="tw-flex tw-justify-center tw-mt-12">
        <div className="tw-flex tw-flex-col tw-max-w-md tw-gap-4 tw-bg-palette-neutral-bg-weak tw-rounded-md tw-drop-shadow-md tw-p-8">
          <h3>Link Accounts</h3>
          <p>
            There is already an account using the email address <code>{email}</code>.
          </p>
          <p>
            Link your accounts by logging in using your previous login method via the <b>Link</b>{' '}
            button below.
          </p>
          <div className="tw-flex tw-gap-3 tw-flex-nowrap">
            <Button onClick={handleLogout} fill="outlined">
              Logout
            </Button>
            <Button onClick={handleLink} loading={loading} data-testid="link-button">
              Link
            </Button>
          </div>
          {error && (
            <Alert
              type="danger"
              title={`Failed to authenticate: ${error.error}`}
              description={error.error_description}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LinkAccountsPage;
