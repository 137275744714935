import React, { useMemo, useState } from 'react';
import LoadError from 'components/application/load-error';
import {
  Button,
  createColumnHelper,
  StickyActionsDataGrid,
  Link,
  LoadingSpinner,
  Switch,
  useDefaultTable,
} from 'components/foundation';
import OrganizationResources, { UpdateOrganizationEntity } from 'remote/resources/organizations';
import { Organization } from 'types/organization';
import { useOrganizationSsoConfigsQuery } from 'remote/resources/sso-configs';
import { SsoConfig } from 'types/sso-config';
import { CreateSsoConfigModal } from 'components/pages/admin/sso-config/create-sso-config-modal';
import { useNotify } from 'state/notifications';

const OrganizationSsoConfigsGuard = (
  component: React.ComponentType<{
    organization: Organization;
    ssoConfigs: SsoConfig[];
    onRefreshSsoConfigs: () => void;
    onRefreshOrganization: () => void;
  }>
) => ({
  organization,
  onRefreshOrganization,
}: {
  organization: Organization;
  onRefreshOrganization: () => void;
}) => {
  const ssoConfigsQuery = useOrganizationSsoConfigsQuery(organization.id); // eslint-disable-line

  const handleRefreshSsoConfigs = () => ssoConfigsQuery.refetch();

  if (ssoConfigsQuery.isLoading) {
    return <LoadingSpinner size="large" expand minHeight={300} />;
  }

  if (ssoConfigsQuery.isError) {
    return <LoadError />;
  }

  const Component = component;
  return (
    <Component
      organization={organization}
      ssoConfigs={ssoConfigsQuery.data}
      onRefreshSsoConfigs={handleRefreshSsoConfigs}
      onRefreshOrganization={onRefreshOrganization}
    />
  );
};

const ssoConfigHeaderHelper = createColumnHelper<SsoConfig>();

export const OrganizationSsoConfigsBase = ({
  organization,
  ssoConfigs,
  onRefreshSsoConfigs,
  onRefreshOrganization,
}: {
  organization: Organization;
  ssoConfigs: SsoConfig[];
  onRefreshSsoConfigs: () => void;
  onRefreshOrganization: () => void;
}) => {
  const [createOpen, setCreateOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const handleGoogleLoginEnabledChange = e => {
    const value = e.target.checked;
    updateOrganization({ googleLoginEnabled: value });
  };

  const handlePasswordLoginEnabledChange = e => {
    const value = e.target.checked;
    updateOrganization({ passwordLoginEnabled: value });
  };

  const handleMicrosoftLoginEnabledChange = e => {
    const value = e.target.checked;
    updateOrganization({ microsoftLoginEnabled: value });
  };

  const updateOrganization = async (entity: UpdateOrganizationEntity) => {
    setLoading(true);
    try {
      await OrganizationResources.update(organization.id, entity);
      onRefreshOrganization();
    } catch (e) {
      notify.error('Failed to update organization');
    }
    setLoading(false);
  };

  const handleCreateSsoConfig = () => setCreateOpen(true);
  const handleCreateClose = () => setCreateOpen(false);
  const handleCreateSuccess = (config: SsoConfig) => {
    onRefreshSsoConfigs();
    notify.success(`SSO Config ${config.ssoConfigId} successfully created!`);
    setCreateOpen(false);
  };

  const columns = useMemo(
    () => [
      ssoConfigHeaderHelper.accessor('ssoConfigId', {
        header: 'ID',
        minSize: 100,
        cell: c => {
          const ssoConfig = c.row.original;

          return (
            <Link href={`#admin/sso-configs/${ssoConfig.ssoConfigId}`}>
              {ssoConfig.ssoConfigId}
            </Link>
          );
        },
      }),
      ssoConfigHeaderHelper.accessor('displayName', {
        header: 'Display Name',
        minSize: 100,
      }),
      ssoConfigHeaderHelper.accessor('idpType', {
        header: 'IDP',
        minSize: 100,
      }),
      ssoConfigHeaderHelper.accessor('clientID', {
        header: 'Client ID',
        minSize: 100,
      }),
    ],
    []
  );

  const table = useDefaultTable({
    columns: columns,
    data: ssoConfigs,
  });

  return (
    <div>
      <h5>SSO (Login methods only)</h5>
      <div className="tw-flex tw-flex-col tw-gap-2 tw-mt-4">
        <Switch
          checked={organization.passwordLoginEnabled}
          label="Allow email/password login"
          disabled={loading}
          onChange={handlePasswordLoginEnabledChange}
          data-testid="email-password-toggle"
        />
        <Switch
          checked={organization.googleLoginEnabled}
          label="Allow Google social login"
          disabled={loading}
          onChange={handleGoogleLoginEnabledChange}
          data-testid="google-toggle"
        />
        <Switch
          checked={organization.microsoftLoginEnabled}
          label="Allow Microsoft social login"
          disabled={loading}
          onChange={handleMicrosoftLoginEnabledChange}
          data-testid="microsoft-toggle"
        />
      </div>
      <div className="tw-flex tw-justify-between tw-mb-2 tw-mt-4">
        <h6>Custom SSO Configs</h6>
        <div className="tw-justify-end tw-gap-2 tw-flex">
          <Button
            onClick={handleCreateSsoConfig}
            iconName="PlusIconOutline"
            fill="outlined"
            data-testid="create-sso-button"
          >
            Create
          </Button>
        </div>
      </div>
      <StickyActionsDataGrid tableInstance={table} />
      <CreateSsoConfigModal
        organizationId={organization.id}
        open={createOpen}
        closeModal={handleCreateClose}
        saveAndClose={handleCreateSuccess}
      />
    </div>
  );
};

export const OrganizationSsoConfigs = OrganizationSsoConfigsGuard(OrganizationSsoConfigsBase);
