import './main-layout.css';

import React, { ReactNode, useEffect, useState } from 'react';

import { useSession, useNavigationState } from 'store';

import SideNavigation from './side-navigation';
import TopNavigation from './top-navigation';
import { InviteConsentModal, shouldShowInvitesConsentForm } from 'application/invite-consent';
import { SessionStore } from 'state';
import { useDarkTheme, useMediaQuery } from 'utils/hooks';
import { breakpoints } from 'utils/breakpoints';
import { Alert, CookieConsent, TextLink } from 'foundation';
import { TenantType } from 'entities/tenant';
import { useQuery } from 'react-query';
import cx from 'classnames';
import Actions from 'actions';
import TenantNotifiations from '../tenant-notifications';
import ExtendTrialModal from '../extend-trial/modal';
import { UpxPreviewBanner } from './upx-preview-banner';
import { Banner } from '@neo4j-ndl/react';
import UsersResource from 'remote/resources/users';
import { useNotify } from 'state/notifications';

export interface Session {
  loggedIn: boolean;
  name?: string;
  email?: string;
  picture?: string;
  loading?: boolean;
  planType?: string;
}

export interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => {
  const session = useSession();
  const navigationState = useNavigationState();
  const isDarkTheme = useDarkTheme();
  const [sideNavVisible, setSideNavVisible] = useState(window.innerWidth >= breakpoints.lg);
  const aboveMobileWidth = useMediaQuery(breakpoints.lg);

  const tenantSuspended = session.tenant?.suspended === true;
  const tenantType = session.tenant?.tenantType;

  const rootPage = navigationState?.currentPage?.root;
  const subView = navigationState?.currentPage?.instanceId;
  const onBillingPage = rootPage === 'settings' && subView === 'billing';

  const displaySuspendedAlert = tenantSuspended && !onBillingPage;
  const displayUnpaidInvoicesAlert = session.tenant?.hasUnpaidInvoices && !onBillingPage;
  const displayMfaRequiredForOrg = session.orgMfaRequired === true;
  const [invoiceAlertVisible, setInvoiceAlertVisible] = useState(
    displayUnpaidInvoicesAlert && tenantType === TenantType.PERSONAL
  );
  useQuery('refresh-tenant', Actions.namespaces.refresh, {
    refetchInterval: 10000,
    enabled: session.loggedIn && !!session.currentTenant,
  });
  useEffect(() => {
    if (sideNavVisible && !aboveMobileWidth) setSideNavVisible(false);
  }, [aboveMobileWidth]);

  useEffect(() => {
    if (isDarkTheme) {
      document.body.classList.add('ndl-theme-dark');
      document.body.classList.remove('ndl-theme-light');
    } else {
      document.body.classList.add('ndl-theme-light');
      document.body.classList.remove('ndl-theme-dark');
    }
  }, [isDarkTheme]);

  const classes = cx('console-content tw-py-12 lg:tw-ml-0', {
    'tw-ml-16': !location.hash.startsWith('#settings'),
  });

  const [showExtendTrialModal, setShowExtendTrialModal] = useState(false);

  return (
    <>
      <UpxPreviewBanner />
      <TopNavigation session={session} isDarkTheme={isDarkTheme} />
      <div className="console-page">
        {session.loggedIn && (
          <SideNavigation
            sideNavVisible={sideNavVisible}
            onSidenavVisibleChange={setSideNavVisible}
          />
        )}
        <div className="content-wrapper">
          <div>{displayMfaRequiredForOrg && <OrgRequiresMfaBanner />}</div>
          <div className={classes} data-testid="console-content">
            {children}
          </div>
        </div>
      </div>
      <CookieConsent />
      {session.loggedIn && (
        <InviteConsentModal
          open={shouldShowInvitesConsentForm(session)}
          onClose={() => SessionStore.setHideInviteConsentForm(true)}
        />
      )}
      {displaySuspendedAlert && <TypeSpecificSuspendedAlert tenant={session.tenant} />}
      {displayUnpaidInvoicesAlert && !displaySuspendedAlert && invoiceAlertVisible && (
        <UnpaidInvoicesAlert onClose={() => setInvoiceAlertVisible(false)} />
      )}
      <TenantNotifiations openExtendTrial={() => setShowExtendTrialModal(true)} />
      <ExtendTrialModal
        open={showExtendTrialModal}
        onClose={() => setShowExtendTrialModal(false)}
      />
    </>
  );
};

const TypeSpecificSuspendedAlert = ({ tenant }) => {
  switch (tenant?.tenantType) {
    case TenantType.PERSONAL:
      return (
        <TenantSuspendedAlert
          actions={[
            {
              label: 'Pay invoice',
              onClick: e => {
                e.preventDefault();
                Actions.navigate.push('?tab=invoices#settings/billing');
              },
            },
          ]}
        >
          This project is suspended due to non-payment. Please finalize any overdue invoices. Your
          instances will be deleted 30 days after suspension if payment is not made.
        </TenantSuspendedAlert>
      );

    case TenantType.N4GCP:
      return (
        <TenantSuspendedAlert>
          The GCP project linked to this project has been deleted or moved into a new account
          without an active Neo4j Aura subscription. Please contact{' '}
          <TextLink href="https://support.neo4j.com/s/" externalLink>
            Aura Support
          </TextLink>{' '}
          if you believe this is a mistake.
        </TenantSuspendedAlert>
      );

    case TenantType.MARKETPLACE_AWS:
    case TenantType.MARKETPLACE_AZURE:
      return (
        <TenantSuspendedAlert>
          The marketplace project linked to this project has been deleted or moved into a new
          account without an active Neo4j Aura subscription. Please contact{' '}
          <TextLink href="https://support.neo4j.com/s/" externalLink>
            Aura Support
          </TextLink>{' '}
          if you believe this is a mistake.
        </TenantSuspendedAlert>
      );

    case TenantType.ENTERPRISE:
      return (
        <TenantSuspendedAlert>
          Your project has been suspended. Please contact{' '}
          <TextLink href="https://support.neo4j.com/s/" externalLink>
            Aura Support
          </TextLink>{' '}
          urgently if you believe this is a mistake.
        </TenantSuspendedAlert>
      );

    default:
      return null;
  }
};

const TenantSuspendedAlert = ({ children, ...rest }) => {
  return (
    <BannerAlert
      type="danger"
      title="Project suspended"
      testId="tenant-suspended-alert-banner"
      maxWidth={620}
      {...rest}
    >
      {children}
    </BannerAlert>
  );
};

const OrgRequiresMfaBanner = () => {
  const session = useSession();
  const notify = useNotify();
  const handleUpdateMfaEnabled = async () => {
    try {
      await UsersResource.updateAppMFA(session.userId, true);
      Actions.navigate.push({ hash: 'logout' });
    } catch (e) {
      notify.error('Failed to enable MFA');
    }
  };
  return (
    <Banner
      icon
      type="danger"
      description="Your organization has required Multi Factor Authentication (MFA). 
        Clicking Enable MFA below will log you out and you will then need to login 
        using your email and password to configure MFA."
      actions={[
        {
          as: 'button',
          label: 'Enable',
          onClick: handleUpdateMfaEnabled,
        },
      ]}
    />
  );
};

const UnpaidInvoicesAlert = ({ onClose }) => {
  return (
    <BannerAlert
      title="Unpaid invoice"
      actions={[
        {
          label: 'Pay Invoice',
          onClick: e => {
            e.preventDefault();
            Actions.navigate.push('?tab=invoices#settings/billing');
          },
        },
        {
          label: 'Contact Support',
          href: 'https://support.neo4j.com/s/',
          target: '_blank',
        },
      ]}
      type="warning"
      onClose={onClose}
      closeable
      maxWidth={620}
      testId="tenant-unpaid-invoices-alert-banner"
    >
      We have been unable to collect payment for your latest invoice. Please update your card
      details and/or pay the invoice to avoid service disruption. This project will be suspended 7
      days after the invoice due date.
    </BannerAlert>
  );
};

const BannerAlert = ({ type, title, testId, maxWidth, children, ...extra }) => {
  return (
    <Alert
      icon
      type={type}
      title={title}
      data-testid={testId}
      className="tw-absolute tw-top-14 tw-right-8 tw-ml-8"
      style={{ maxWidth: maxWidth, zIndex: 5 }}
      {...extra}
    >
      <div className="n-body-medium">{children}</div>
    </Alert>
  );
};

export default MainLayout;
