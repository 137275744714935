import React from 'react';
import { useSession } from 'store';
import { DisabledProductPage } from 'pages/disabled-product';
import { isProductAvailable, TenantType } from 'entities/tenant';
import { Product } from 'types/product';

export const productGuard = (Component: React.ComponentType) => {
  const func = function ProductGuard(props) {
    const { product, providerConfigs, tenant } = useSession();

    if (tenant?.tenantType === TenantType.MARKETPLACE_AZURE && product === Product.AURA_DS) {
      return <DisabledProductPage product={product} />;
    }

    if (!isProductAvailable(providerConfigs, product)) {
      return <DisabledProductPage product={product} />;
    }

    return <Component {...props} />;
  };

  const componentName = Component.displayName || Component.name || 'Component';

  func.displayName = `ProductGuard(${componentName})`;

  return func;
};
